import { Module } from "vuex";
import { SecurityGrantModel, SecurityModel, SecurityRoleModel, SecurityState } from "./model";
import api from "@/services/api";
import responses from "@/services/responses";

const SecurityModule: Module<SecurityState, unknown> = {
  namespaced: true,
  state(): SecurityState {
    return {
      active: [],
      roles: null,
    };
  },
  mutations: {
    setState(state: SecurityState, partialState: Partial<SecurityState>): void {
      Object.assign(state, partialState);
    },
    setActiveModel(state: SecurityState, activeModel: SecurityModel[]): void {
      state.active = activeModel;
    },
    setRoles(state: SecurityState, roles: string[]): void {
      state.roles = roles;
    },
  },
  getters: {
    isEapLM(state: SecurityState): boolean {
      return state.eapLM != undefined ? state.eapLM : false;
    },
  },
  actions: {
    async getUser({ dispatch }, securityId: string): Promise<void> {
      const users = await api.security.getUser(securityId);
      dispatch("setUser", users);
    },
    async searchUser({ dispatch }, id: string): Promise<void> {
      const users = await api.security.searchUser(id);
      dispatch("setUser", users);
    },
    async setUser({ commit, dispatch }, users: SecurityModel[] | null): Promise<void> {
      if (!users) {
        return dispatch("clearUser");
      }
      commit("setActiveModel", users);
    },
    async putUser({ commit }, userModel: SecurityModel): Promise<string> {
      let status;
      const user = await api.security.updateUser(userModel);
      if (typeof user === "string") {
        status = user ? user : responses.FAILURE;
      } else {
        commit("setActiveModel", [user]);
        status = responses.SUCCESS;
      }
      return status;
    },
    async clearUser({ commit }): Promise<void> {
      commit("setActiveModel", []);
    },
    async getRoles({ commit }): Promise<void> {
      const roles = await api.security.getRoles();
      if (roles) commit("setRoles", roles);
    },
    async grantUserRole({ commit, state }, grantRole: SecurityRoleModel): Promise<string | void> {
      if (state.active.length == 1) {
        const roleModel: SecurityRoleModel = {
          role: grantRole.role,
          scopes: grantRole.scopes,
        };
        const roles: SecurityGrantModel = {
          roles: [roleModel],
        };
        let user = null;
        if (state.active[0].securityId) {
          user = await api.security.grantUserRole(state.active[0].securityId, roles);
          if (typeof user === "string") {
            return user;
          }
        }
        if (user) commit("setActiveModel", [user]);
      } else {
        console.log("Error: attempt to grantUserRole containing " + state.active.length + " records");
      }
    },
    async revokeUserRole({ commit, state }, revokeRole: SecurityRoleModel): Promise<void> {
      if (state.active.length == 1) {
        const roleModel: SecurityRoleModel = {
          role: revokeRole.role,
          scopes: revokeRole.scopes,
        };
        const roles: SecurityGrantModel = {
          roles: [roleModel],
        };
        let user = null;
        if (state.active[0].securityId) {
          user = await api.security.revokeUserRole(state.active[0].securityId, roles);
        }
        if (user) commit("setActiveModel", [user]);
      } else {
        console.log("Error: attempt to revokeUserRole containing " + state.active.length + " records");
      }
    },
  },
};

export default SecurityModule;
