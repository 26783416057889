import { AxiosError } from "axios";
import { DownloadModel } from "@/store/modules/Download/model";
import { http } from "@/services/http";
import routes from "@/services/routes";
import { DateTime } from "luxon";

const Download = {
  getMaterial(
    body: {
      learningEventId: string;
      learnerSecurityId?: string;
    },
    onDownloadProgress?: (progressEvent: ProgressEvent) => void,
    isLg = true
  ): Promise<DownloadModel | null> {
    const date = DateTime.now().toISO({ includeOffset: false });
    const baseUrl = `${routes().DOWNLOADS}`;
    const url = isLg ? baseUrl : `${baseUrl}/prework`;
    return http
      .post<DownloadModel>(url, { ...body, date }, { timeout: 300000, onDownloadProgress })
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        console.log(error.message);
        return null;
      });
  },
};
export default Download;
