import { ClientContentModel, ClientModel, ClientUpdateModel, OrderModel } from "@/store/modules/Client/model";
import { http } from "@/services/http";
import routes from "@/services/routes";
import responses from "@/services/responses";

const Client = {
  async getClientById(
    id: string,
    type: "internalId" | "externalId" = "internalId",
    eap?: string[]
  ): Promise<ClientContentModel | ClientModel | null> {
    let endpoint: string = eap == undefined ? "" : `eap=${eap}`;
    if (type === "externalId" && id) endpoint = `?${type}=${id}&${endpoint}`;
    else endpoint = `${id}?${endpoint}`;
    return http
      .get<ClientContentModel>(routes(endpoint).CLIENTS)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  async getClientByName(
    id: string,
    pageNumber?: number,
    pageSize?: number,
    eap?: string[]
  ): Promise<ClientContentModel | ClientModel | null> {
    let endpoint: string = eap == undefined ? "" : `eap=${eap}`;
    endpoint = pageNumber != undefined ? `${endpoint}&pageNumber=${pageNumber}` : endpoint;
    endpoint = pageSize != undefined ? `${endpoint}&pageSize=${pageSize}` : endpoint;
    endpoint = `?clientName=${id}&${endpoint}`;
    return http
      .get<ClientContentModel>(routes(endpoint).CLIENTS)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  /**
   * {@link #getClientById} and {@link #getClientByName} may return a single
   * `ClientContentModel` or a collection of `ClientContentModel`s nested inside
   * `ClientModel.content`. This utility method resolves that variability to return
   * a `ClientContentModel[]` in all cases: wrapping a stand-alone `ClientContentModel`
   * as single-element array and otherwise returning `ClientModel.content`.
   */
  asClientContentModels(getClientResponse: ClientContentModel | ClientModel | null): ClientContentModel[] | null {
    if (getClientResponse) {
      if ("content" in getClientResponse) {
        return (getClientResponse as ClientModel).content;
      } else {
        return [getClientResponse as ClientContentModel];
      }
    } else {
      return null;
    }
  },
  getClients(eap?: string[]): Promise<ClientModel> {
    const endpoint = eap == undefined ? "" : `?pageSize=1000&eap=${eap}`;

    return http
      .get<ClientModel>(routes(endpoint).CLIENTS)
      .then((response) => response.data)
      .catch(() => {
        return { content: [] };
      });
  },
  updateTokens(
    id: string,
    courseId: string,
    tokenCount: number,
    op: "add" | "remove"
  ): Promise<ClientContentModel | null> {
    return http
      .post<ClientContentModel>(routes(`tokens/${op}/${id}`).CLIENTS, { courseId, tokenCount })
      .then((response) => response.data)
      .catch(() => null);
  },

  addChangeOrder(model: OrderModel): Promise<OrderModel | string> {
    return http
      .post<OrderModel>(routes().ORDERS, model)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },

  updateClient(client: ClientUpdateModel): Promise<ClientContentModel | string> {
    return http
      .put(routes(client.id).CLIENTS, client)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
  getUnallocatedLicenseReport(): Promise<ClientContentModel[]> {
    return http
      .post<ClientContentModel[]>(routes("unallocated_licenses").CLIENTS)
      .then((response) => response.data)
      .catch((err) => {
        console.error("Error occured while pulling unallocated license report " + err);
        return [];
      });
  },
};
export default Client;
