import { Module } from "vuex";
import { ClientState } from "./model";
import api from "@/services/api";

const ClientModule: Module<ClientState, unknown> = {
  namespaced: true,
  state() {
    return {
      clients: { content: [] },
    };
  },
  mutations: {
    setClientState(state: ClientState, clientStateOptions: Partial<ClientState>): void {
      Object.assign(state, clientStateOptions);
    },
  },
};

export default ClientModule;
