<template lang="pug">
#ToastStuff
  #toast-container
  .markup(v-html="toastMessageTemplateSrc()")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class Toast extends Vue {
  private toastMessageTemplateSrc(): string {
    let templateSrc = `
      <template id="toast-message-template">
        <div>
          <div class="toast-icon">
            <svg class="info" width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4400_17995)"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00008 2.00008C4.68637 2.00008 2.00008 4.68637 2.00008 8.00008C2.00008 11.3138 4.68637 14.0001 8.00008 14.0001C11.3138 14.0001 14.0001 11.3138 14.0001 8.00008C14.0001 4.68637 11.3138 2.00008 8.00008 2.00008ZM0.666748 8.00008C0.666748 3.94999 3.94999 0.666748 8.00008 0.666748C12.0502 0.666748 15.3334 3.94999 15.3334 8.00008C15.3334 12.0502 12.0502 15.3334 8.00008 15.3334C3.94999 15.3334 0.666748 12.0502 0.666748 8.00008Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 7.33325C8.36811 7.33325 8.66659 7.63173 8.66659 7.99992V10.6666C8.66659 11.0348 8.36811 11.3333 7.99992 11.3333C7.63173 11.3333 7.33325 11.0348 7.33325 10.6666V7.99992C7.33325 7.63173 7.63173 7.33325 7.99992 7.33325Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.33325 5.33341C7.33325 4.96522 7.63173 4.66675 7.99992 4.66675H8.00659C8.37477 4.66675 8.67325 4.96522 8.67325 5.33341C8.67325 5.7016 8.37477 6.00008 8.00659 6.00008H7.99992C7.63173 6.00008 7.33325 5.7016 7.33325 5.33341Z" fill="white"/></g><defs><clipPath><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
            <svg class="error" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.4855 3.51525C15.7985 -1.17175 8.2005 -1.17175 3.5145 3.51525C-1.1715 8.20125 -1.1715 15.7993 3.5145 20.4853C8.2005 25.1713 15.7985 25.1713 20.4855 20.4853C25.1525 15.7913 25.1525 8.21025 20.4855 3.51525ZM16.6925 15.2933C17.0835 15.6843 17.0835 16.3163 16.6925 16.7073C16.4975 16.9023 16.2415 17.0002 15.9855 17.0002C15.7295 17.0002 15.4735 16.9023 15.2785 16.7073L11.9855 13.4142L8.6925 16.7073C8.4975 16.9023 8.2415 17.0002 7.9855 17.0002C7.7295 17.0002 7.4735 16.9023 7.2785 16.7073C6.8875 16.3163 6.8875 15.6843 7.2785 15.2933L10.5715 12.0002L7.2785 8.70725C6.8875 8.31625 6.8875 7.68425 7.2785 7.29325C7.6695 6.90225 8.3015 6.90225 8.6925 7.29325L11.9855 10.5863L15.2785 7.29325C15.6695 6.90225 16.3015 6.90225 16.6925 7.29325C17.0835 7.68425 17.0835 8.31625 16.6925 8.70725L13.3995 12.0002L16.6925 15.2933Z" fill="black"/></svg>
            <svg class="success" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C23.981 5.381 18.619 0.019 12 0ZM19.207 7.707L10.207 16.707C10.012 16.902 9.756 17 9.5 17C9.244 17 8.988 16.902 8.793 16.707L4.793 12.707C4.402 12.316 4.402 11.684 4.793 11.293C5.184 10.902 5.816 10.902 6.207 11.293L9.5 14.586L17.793 6.293C18.184 5.902 18.816 5.902 19.207 6.293C19.598 6.684 19.598 7.316 19.207 7.707Z" fill="#ECECEC"/></svg>
          </div>
        </div>
        <div>
          <div class="toast-title" data-slot="title"></div>
          <div class="toast-body" data-slot="body"></div>
        </div>
        <div>
          <div data-dismiss-button="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18M6 6l12 12"/></svg></div>
        </div>
      </template>
    `;
    // strip ignorable whitespace
    templateSrc = templateSrc.replace(/>\n\s*</g, "><").trim();
    console.log(templateSrc);
    return templateSrc;
  }
}
</script>
<style>
/* #toast-container is the element into which toast notification will be inserted */
#toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999;
}

/* each toast message is wrapped by `[data-data-type="ToastMessageElement"]` (note `.pdl-toast2-msg` would also work) */
#toast-container [data-data-type="ToastMessageElement"] {
  display: grid;
  grid-template-columns: 24px auto 16px;
  width: 320px;
  border: 1px solid #555555;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: black;
  background: white;
  padding: 6px 2px 8px 2px;
  margin-bottom: 8px;
  border-left-width: 8px;
  position: relative;
  overflow: hidden;
}

/********************************************** SHOW / HIDE TOAST ANIMATION **/
#toast-container [data-data-type="ToastMessageElement"] {
  animation: displaying 0.35s;
}
/* `[data-active="false"]` marks a toast message that's about to be dismissed  */
#toast-container [data-data-type="ToastMessageElement"][data-active="false"] {
  animation: dismissing 0.4s;
  animation-fill-mode: forwards;
}
@keyframes displaying {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dismissing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/******************************************************************************/

/************************************************************** CLOSE BUTTON **/
/* `[data-dismiss-button="true"]` marks the close button, if any */
#toast-container [data-data-type="ToastMessageElement"] [data-dismiss-button="true"] {
  position: absolute;
  top: 0px;
  right: 4px;
  height: 12px;
  width: 12px;
}
#toast-container [data-data-type="ToastMessageElement"] [data-dismiss-button="true"] svg {
  height: 12px;
  width: 12px;
  display: inline;
}
#toast-container [data-data-type="ToastMessageElement"] [data-dismiss-button="true"] svg path {
  stroke: var(--gray-50);
  stroke-width: 3;
  transition: stroke 0.2s linear, stroke-width 0.2s linear;
}
#toast-container [data-data-type="ToastMessageElement"] [data-dismiss-button="true"]:hover svg path {
  stroke: var(--gray-85);
  stroke-width: 4;
}
/*== generic close button handling... =======================================*/
/* ...hide when NOT enabled for a given toast message */
#toast-container [data-data-type="ToastMessageElement"][data-can-dismiss="false"] [data-dismiss-button="true"],
#toast-container [data-data-type="ToastMessageElement"][data-has-dismiss-button="false"] [data-dismiss-button="true"] {
  display: none;
}
/* ...show when IS enabled for a given toast message */
#toast-container
  [data-data-type="ToastMessageElement"][data-can-dismiss="true"][data-has-dismiss-button="true"]
  [data-dismiss-button="true"] {
  display: block;
  cursor: pointer;
}
/* ...show the whole message toast message as clickable if "click anywhere to dismiss" is active*/
#toast-container [data-data-type="ToastMessageElement"][data-can-dismiss="true"][data-dismiss-on-click="true"] {
  cursor: pointer;
}
/*****************************************************************************/

/*************************************************** TOAST MESSAGE ELEMENTS **/
/*== data slots =============================================================*/
#toast-container [data-data-type="ToastMessageElement"] .toast-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-top: 3px;
}
#toast-container [data-data-type="ToastMessageElement"] .toast-body {
  font-size: 14px;
  margin-top: 4px;
}
/*== icon ===================================================================*/
#toast-container [data-data-type="ToastMessageElement"] .toast-icon {
  width: 24px;
  height: 24px;
}
#toast-container [data-data-type="ToastMessageElement"] .toast-icon svg {
  max-width: 16px;
  max-height: 16px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 4px;
  /* hide all toast-icons by default, re-enabled by wrapper class name */
  display: none;
}
/*-- class-name (category) styling ------------------------------------------*/
/* success style */
#toast-container [data-data-type="ToastMessageElement"].success {
  border-left-color: var(--success-80);
}
#toast-container [data-data-type="ToastMessageElement"].success .toast-icon svg.success path {
  fill: var(--success-80);
}
#toast-container [data-data-type="ToastMessageElement"].success .toast-icon svg.success {
  display: inline;
}
/* error style */
#toast-container [data-data-type="ToastMessageElement"].error {
  border-left-color: var(--error-80);
}
#toast-container [data-data-type="ToastMessageElement"].error .toast-icon svg.error path {
  fill: var(--error-80);
}
#toast-container [data-data-type="ToastMessageElement"].error .toast-icon svg.error {
  display: inline;
}
/* info style */
#toast-container [data-data-type="ToastMessageElement"].info {
  border-left-color: var(--info-80);
}
#toast-container [data-data-type="ToastMessageElement"].info .toast-icon svg.info path {
  fill: var(--info-80);
}
#toast-container [data-data-type="ToastMessageElement"].info .toast-icon svg.info {
  display: inline;
}
/*******************************************************************************/
</style>
